header {
    width: 100%;
    padding: 15px 0 1px 0;

    background-color: var(--primary-dark-color);
    box-shadow: 2px 2px 10px #999;
}

h1 {
    font-size: 4.2rem;
    line-height: 110%;
    margin: 2.1rem 0 1.68rem 0;
}

header h1 {
    font-size: 1rem;

    margin: 0;

    text-align: center;

    color: rgb(255, 255, 255);
}

.logo-section {
    margin-bottom: 1.4em;
}