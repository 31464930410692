@import url(https://fonts.googleapis.com/css?family=Lato:400,700,300,900&display=swap);
@import url("https://unpkg.com/open-props");

.container {
    max-width: 1200px;
    min-height: 90%;
}

:root {
    --background-gray: hsl(212 40% 94%);
    --primary-color: rgb(0, 113, 187);
    --primary-dark-color: rgb(0, 67, 112);
    --tag-color1: #54add3;
    --tag-color2: #60bd6c;
    --tag-color3: #f7db64;
    --tag-color4: #eb6a56;
    --tag-color5: #999;
}

body {
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    
}

h1,h2,h3,h4,h5,p,span,div {
    font-family: 'Lato', sans-serif !important;
}

.btn {
    color: white;
    background: rgb(0, 67, 112);
}
h4 {
    color: #004370;
    font-weight: 900;
    border-bottom: 1px dashed #999;
    padding-bottom: 0.2em;
}


.margintop-2 {
	margin-block-start: 5em;
}